@import "../../variables.scss";

.BackButton{
    
        position: absolute;
        right: 0;
        border: 1px solid black;
        background: none;
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        font-size: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;

        
        &:hover{
            background-color: $anchors-bg-color;
            transition: all 0.3s ease-in-out;
        }


        svg{
            font-size: 1rem;
            margin-right: 5px;
        }
    
}

@media only screen and (max-width: 800px){

    .BackButton{
        right: 10px;
    }

}
