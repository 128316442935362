@import "../../variables.scss";

.ProjectList{
    min-height: 100vh;
    background-color: rgb(240, 240, 240);
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .container{
        position: relative;
        min-width: clamp(300px, 80vw, 800px);

        h3{
            margin: 0 0 20px 0;
        }
    
        .project{
            width: clamp(300px, 80vw, 800px);
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: rgb(245, 245, 245);
            box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.15);
            border-radius: 5px;
            margin: 10px 0;
            padding: 10px;
            transition: all 0.2s ease-in-out;
    
            &:hover{
                background-color: $anchors-bg-color;
                transition: all 0.2s ease-in-out;
            }
    
            img{
                width: 50px;
                aspect-ratio: 1/1;
                object-fit: cover;
                object-position: center;
                border-radius: 5px;
                margin: 0 10px;
            }
    
            .btn-container{
                width: 12%;
                height: 100%;
                align-self: flex-end;
                display:flex;
                justify-content: space-between;
    
                .deleteButton, .editButton{
                    aspect-ratio: 1/1;
                    height: 100%;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
        
                    &:hover{
                        transition: all 0.2s ease-in-out;
                    }
                }
        
                .deleteButton{
                    background-color: rgb(255, 154, 154);
        
                    &:hover{
                        background-color: rgb(255, 0, 0);
                    }
                }
        
                .editButton{
                    background-color: rgb(255, 213, 154);
                    display: flex;
                    align-items: center;
                    justify-content: center;
        
                    .editIcon{
                        width: 50%;
                        height: auto;
                    }
        
                    &:hover{
                        background-color: rgb(255, 187, 0);
                    }
        
                }
            }
        }
    }


}



@media only screen and (max-width: 800px){

    .ProjectList{

        .container{
            width: 100%;
            padding: 15px 10px;
            
            .project{
                width: 100%;
    
                h4{
                    font-size: 0.75rem;
                }
    
                .btn-container{
                    width: auto;
                    gap: 10px;
    
                    .deleteButton, .editButton{
                        height: 75%;
                    }
                }
            }

        }
        
    }

}