
@import '../../variables.scss';

.glitch {
position: relative;
color: #fff;
}

.line {
&:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
}

@for $i from 1 through 10 {
    &:nth-child(#{$i}) {
    animation:
        // clip 3000ms $i * -300ms linear infinite,
        // glitch#{$i} 500ms random(1000) * -1ms linear infinite;
        clip 3000ms $i * -300ms linear infinite, 
        glitch#{$i} 5000ms random(1000) * -1ms linear infinite;
    
    @keyframes glitch#{$i} {
        0% {
        transform: translateX(0);
        }
        80% {
        transform: translateX(0);
        // color: #fff;
        color: #44443f;
        }
        85% {
        transform: translateX(random(10) - 5px);
        color: #4E9A26;
        }
        90% {
        transform: translateX(random(10) - 5px);
        // color: #AC1212;
        color: $anchors-bg-color;
        }
        95% {
        transform: translateX(random(10) - 5px);
        color: #fff;
        }
        100% {
        transform: translateX(0);
        }
    }
    }
}
}

@keyframes clip {
0% {
    clip-path: polygon(
    0 100%,
    100% 100%,
    100% 120%,
    0 120%
    );
}

100% {
    clip-path: polygon(
    0 -20%,
    100% -20%,
    100% 0%,
    0 0
    );
}
}
