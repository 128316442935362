@import '../../variables.scss';

.BlancoDeCazador{

    //background-color: lime;
    min-width: 100%;
    
    .bdc-img-container{
        // background-color: salmon;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        margin-right: clamp(0px, 6vw, 350px);
        margin-top: clamp(0px, 15vh, 300px);

        img{
            width: clamp(200px, 8vw, 400px);
            aspect-ratio: 2.6 / 2.1;
            object-fit: cover;
            filter: grayscale(100%)  contrast(65%);
        }
    }

    .bdc-text-container{
        // background-color: lightblue;
        position: fixed;
        top: clamp(0px, 15vh, 300px);
        left: 0;
        overflow-y: auto;
        width: clamp(500px, 45%, 1000px);
        padding-left: clamp(0px, 3vw, 100px);
        height: 85vh;
        //scrollbar-width: none; //Sólo funciona en Firefox

        .bdc-text-inner-container{
           //background-color: yellowgreen;
            margin-bottom: 50px;

            a{
                font-weight: 500;
            }

            a:hover{
                background-color: $anchors-bg-color;
                
            }
        }

        p:first-of-type{
            text-align: justify;
        }

        details{
            margin-top: 10px;
            
            summary{
                transition: all 0.2s ease-in-out;
                padding: 10px;
                cursor: pointer;
            
                &:hover{
                    background-color: $anchors-bg-color;
                    transition: all 0.2s ease-in-out;
                }
            }

            summary::marker{
                content: '';
            }

            div{
                margin-left: 0.65rem;
            }

        }
    }
    
}

@media only screen and (max-width: 800px)  {
    .BlancoDeCazador{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        .bdc-img-container{
            position: static;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: auto;
            margin-top: clamp(100px, 3vw, 200px);
            margin-bottom: clamp(0px, 3vw, 100px);
            margin-inline: clamp(10px, 4vw, 50px);

            img{
                width: 30%;
            }
        }

        .bdc-text-container{
            clear: both;
            position: static;
            height: auto;
            width: calc(90% + 30px);
            margin: 0;
            padding-top: clamp(10px, 4vw, 50px);
            padding-bottom: 50px;
            padding-inline: 0;
        }
    }
}

@media only screen and (max-width: 550px)  {
    .BlancoDeCazador{
        .bdc-img-container{

            img:nth-of-type(1), img:nth-of-type(2){
                width: calc(45% + 15px/2);
            }

            img:nth-of-type(3){
                display: none;
            }
        }

    }
}



/* TODO: Estilos de la versión de la página conectada a la API
.BlancoDeCazador{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    padding: 10vh 3vw;


    .bdc-img-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1vw;
        align-self: flex-start;

        img{
            width: clamp(200px, 5vw, 300px);
            aspect-ratio: 2.6 / 2.1;
            object-fit: cover;
            filter: grayscale(100%)  contrast(65%);
        }
    }

    .bdc-text-container{
        width: clamp(500px, 30vw, 1000px);
    }
    
}

*/