@import '../../variables.scss';

.Contact{
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100vh;
        padding: 0 5vw;
    
    
        .contact-img-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1vw;
    
            img{
                width: clamp(200px, 90vh, 300px);
                aspect-ratio: 3.7 / 4.6;
                object-fit: cover;
                filter: grayscale(100%)  contrast(65%);
            }
        }
    
        .contact-text-container{
            align-self: flex-start;
            margin-top: 10vh;

            p{
                line-height: 1.5rem;
            }

            a{
                text-decoration: none;

                &:hover{
                    background-color: $anchors-bg-color;
                }
            }
    }
    
}