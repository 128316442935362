@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './variables.scss';


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: Inter;
    color: $primary-font-color;
}

body{
    background-color: $primary-color;
}

a{
    text-decoration: none;
}

p, summary{
    font-size: 0.8rem;
}

p{
    line-height: 1.2rem;
}