@import "../../variables.scss";

.Login {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.form_container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	h2{
		font-size: 30px;
		font-weight: 600;
		margin: 10px 0;
	}

	.input {
		outline: none;
		border: none;
		width: 370px;
		padding: 15px;
		border-radius: 10px;
		background-color: white;
		margin: 5px 0;
		font-size: 14px;
	}
	
	.error_msg {
		width: 370px;
		padding: 15px;
		margin: 5px 0;
		font-size: 14px;
		background-color: #f34646;
		color: white;
		border-radius: 5px;
		text-align: center;
	}
	
	.login_btn {
		border: none;
		outline: none;
		padding: 12px 0;
		margin-top: 12px;
		border-radius: 5px;
		width: 180px;
		font-size: 14px;
		cursor: pointer;
		background-color: $anchors-bg-color;
		width: 100%;
		transition: all 0.3s ease;

		&:hover{
			background-color: rgb(199, 221, 55);
			transition: all 0.3s ease;
		}
	}

}



@media (max-width: 768px) {

	.Login {
		// background-color: white;

		.login_form_container {
			max-width: 370px;
			box-shadow: none;
		}

		.input {
			width: 360px;
		}
	}
}
