@import '../variables.scss';

.Layout {
	position: relative;

	#logo {
		position: fixed;
		top: clamp(10px, 4vw, 30px);
		right: clamp(10px, 4vw, 50px);
		text-decoration: none;
		font-size: 1.2rem;
		z-index: 10;

		&:hover {
			background-color: $anchors-bg-color;
		}
	}
}


@media only screen and (max-width: 550px){

	.Layout {
		#logo {
			position: absolute;
		}
	}
}