//Variables

// $primary-color: #f0f1f1;
$primary-color: #fafefe;
$primary-font-color: #44443f;
$anchors-bg-color: rgba(228, 255, 63, 1);


//Mixins
@mixin plantillas($url){
    background: url($url);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}