@import '../../variables.scss';

.Home {

	// Mixin para incluir la plantilla de canva
	// @include plantillas('../../assets/planstillas canva/home.png');
	
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	.home-content{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 0 3vw;
		width: clamp(800px, 60vw, 1000px);
		height: 65vh;
		gap: 2vw;
	}

	.home-texts {
		// background-color: deeppink;

		width: 35%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	#home-title {
		// background-color: dodgerblue;
		font-size: 1.2rem;
		font-weight: 400;
	}

	a {
		text-decoration: none;
		font-weight: 300;
	}

	nav {
		// background-color: chartreuse;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		flex-basis: 90%;
		width: 100%;

		div {
			margin: 1% 0;
			width: 100%;
		}

		a {
			//background-color: orangered;
			font-family: Helvetica, sans-serif, Arial;
			text-decoration: none;
			font-weight: 400;
			font-size: 0.75rem;
			display: block;
			width: 100%;
			padding: 2px;
		}

		a:hover {
			// text-decoration: line-through orangered;
			background-color: $anchors-bg-color;
		}
	}

	#home-img-container {
		width: clamp(230px, 35%, 400px);
		//width: 35%;
		aspect-ratio: 3.6 / 5.7;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		// filter: grayscale(100%)  contrast(65%);
	}
}

// MEDIA QUERY

@media screen and (max-width: 600px) {
	.Home{
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;


		.home-content{
			flex-direction: column-reverse; //Con esta propiedad invierto el orden para que se muestre arriba el gif
			justify-content: flex-start;
			align-items: center;
			width: 100vw;
			height: auto;
			padding: 0;
		}
	
		.home-texts {
			flex-basis: 20%;
			width: 100%;
			margin: 2rem 0;
			align-self: flex-start;
			gap: 1rem;
			padding: 0 2rem;

			.GlitchedLink {
				font-size: clamp(1.2rem, 2.5vw, 3rem);
				margin-bottom: 1rem;
			}

			nav a{
				font-size: clamp(1rem, 2vw, 2rem);
			}
		}

		#home-img-container {
			width: 100%;
			height: auto;
			max-height: 30vh;
		}
	}
}
