@import '../../variables.scss';

.Project{

    // Mixin para incluir la plantilla de canva
	// @include plantillas('../../assets/plantillas canva/project-2.png');
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: clamp(0px, 3vw, 100px);

    .project-media-container{
        //background-color: salmon;
        margin-top: clamp(0px, 15vh, 300px);
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .project-img-container{
            //background-color: dodgerblue;
            display: flex;
            gap: 15px;
    
            img{
                width: clamp(200px, 8vw, 400px);
                aspect-ratio: 2.6 / 2.1;
                object-fit: cover;
                cursor: pointer;
            }
        }

        .external-video{
            iframe{
                max-width: 100%;
            }
        }

        .audio-link-container{
            display: flex;
            align-items: flex-start;
        }
    }


    video{
        width: clamp(415px, 16vw, 800px);
        height: auto;
    }


    audio{
        justify-self: center;
    }

    .audio-link-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        a{
            
            &:hover {
                background-color: $anchors-bg-color;
            }
        }
    }

    .project-text-container{
        position: fixed;
        top: clamp(0px, 15vh, 300px);
        right: 0;
        overflow-y: auto;
        width: clamp(500px, 45%, 1000px);
        padding-right: clamp(0px, 6vw, 350px);
        height: 85vh;

        a{
            font-style: italic;
            &:hover{
                background-color: $anchors-bg-color;
            }
        }

        .project-text-inner-container{
            //background-color: yellowgreen;
            padding: 0 clamp(0px, 1vw, 30px);
            margin-bottom: 50px;
        }

        h5, p{
            text-align: justify;
        }
    }

}


@media only screen and (max-width: 960px)  {
    .Project{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        padding-inline: clamp(10px, 1vw, 20px);
        
        .project-media-container{
            position: static;
            width: 100%;
            height: auto;
            margin-top: clamp(100px, 3vw, 200px);
            margin-bottom: clamp(0px, 3vw, 100px);

            .project-img-container{
                align-items: center;
                justify-content: center;
                
                img{
                    width: calc(50% - 15px/2);
                }
            }

            video{
                width: 100%;
            }
        }

        .project-text-container{
            clear: both;
            position: static;
            height: auto;
            width: 100%;
            margin: 0;
            padding-top: clamp(10px, 4vw, 50px);
            padding-bottom: 50px;
            padding-inline: 0;
        }

        .audio-link-container{
            margin-top: 20px;
        }
    }
}

@media only screen and (max-width: 550px)  {
    .Project{
        .project-img-container{

            img:nth-of-type(1), img:nth-of-type(2){
                width: calc(45% + 15px);
            }
        }
    }
}