@import '../../variables.scss';

.Projects{

    // Mixin para incluir la plantilla de canva
	// @include plantillas('../../assets/plantillas canva/projects.png');

    width: 100%;
    padding-top: clamp(0px, 15vh, 300px);
    padding-inline: clamp(0px, 3vw, 100px);
    padding-bottom: clamp(0px, 3vw, 100px);
    position: relative;
    // background-color: yellow;

    .projects-img-container{
        // background-color: salmon; 
        width: clamp(300px, 70vw, 1000px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
    
        a{
            cursor: pointer;
            width: clamp(200px, 8vw, 400px);
            aspect-ratio: 2.6 / 2.1;
            overflow: hidden;
            position: relative;
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                filter: grayscale(100%)  contrast(65%);
                transform: scale(1);
                transition: filter 1s, transform 1s;

                &:hover{
                    filter: grayscale(0%)  contrast(120%);
                    transform: scale(1.1);
                    transition: filter 0.7s, transform 0.7s;
                }
            }

            p{
                display: none;
            }
        }

    }

    .projects-text-container{
        // background-color: deepskyblue;
        font-weight: 600;
        position: fixed;
        z-index: 3;
        top: 15vh;
        left: clamp(700px, 75vw, 950px);
    }


}

@media only screen and (max-width: 850px)  {
    .Projects{
        .projects-img-container{
            width: 100%;
            justify-content: center;

            a{
                // background-color: salmon;
                padding-bottom: 1.2rem;
                aspect-ratio: 2.6 / 2.5;

                img{
                    height: 85%;
                    
                    &:hover{
                        filter: grayscale(100%)  contrast(65%);
                        transform: scale(1);
                        transition: filter 1s transform 1s;
                        transition: filter 0.7s, transform 0.7s;
                    }
                }

                p{
                    display: block;
                    text-align: right;
                    font-size: 0.75rem;
                }
            }
        }

        .projects-text-container{
            display: none;
        }
    }
    
}

@media only screen and (max-width: 550px)  {
    .Projects{
        padding-bottom: 100px;

        .projects-img-container{

            gap: 0px;
            
            a{
                // background-color: salmon;
                width: 100%;
                aspect-ratio: 2.6 / 2.1;

                img{
                    height: 90%;
                }

                p{
                    line-height: 1.5rem;
                }
            }
        }

        padding-bottom: 40px;
    }
}