.CreateAbout{
    min-height: 100vh;
    background-color: rgb(240, 240, 240);
    padding-top: 50px;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;

    .container{
        width: clamp(300px, 80vw, 800px); 
        position: relative;

        .CreateAbout-form{
            margin: 0 auto;
            height: auto;
            // border: 1px solid #000;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
    
            h3{
                margin: 10px 0;
            }
    
            input{
                display: block;
                width: 100%;
                margin: 10px 0;
                border: none;
                padding: 7px;
                border-radius: 5px;
            }
    
            textarea{
                display: block;
                width: 100%;
                min-height: 100px;        
                margin: 10px 0;
                border: none;
                padding: 10px;
                border-radius: 5px;
            }
    
            input[type="file"]{
                background-color: white;
            }
    
            .submit-btn{
                display: block;
                width: 100%;
                margin: 10px 0;
                padding: 10px;
                background-color: yellow;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
    
                &:hover{
                    background-color: rgb(214, 214, 115);
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }
}