@import "../../variables.scss";

.Logout{
    button{
        position: absolute;
        right: 0;
        border: 1px solid black;
        background: none;
        cursor: pointer;
        padding: 5px;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        font-size: 0.7rem;
        
        &:hover{
            background-color: $anchors-bg-color;
            transition: all 0.3s ease-in-out;
        }
    }
}