@import '../../variables.scss';

/* Estilos del carrusel - Ajustados a la estructura de Project.scss */
.carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85); // Fondo oscuro con opacidad para resaltar el carrusel
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.carousel {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-image {
    width: clamp(300px, 80vw, 1000px); // Adaptación según Project.scss para mantener consistencia
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    aspect-ratio: 2.6 / 2.1; // Mantiene proporciones coherentes con Project.scss
}

/* Botón de cierre del carrusel */
.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(0, 0, 0, 0.5);
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background 0.2s ease-in-out;

    &:hover {
        color: #c9c9c9; 
    }
}

/* Botones de navegación del carrusel */
.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(0, 0, 0, 0.5);
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background 0.2s ease-in-out;

    &:hover {
        color: #c9c9c9; 
    }
}

.prev-button {
    left: 20px;
}

.next-button {
    right: 20px;
}

/* Estilos responsivos para el carrusel */
@media only screen and (max-width: 960px) {
    .carousel-image {
        width: clamp(250px, 70vw, 800px); // Ajuste del tamaño para pantallas medianas
    }

    .close-button, .nav-button {
        padding: 8px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 550px) {
    .carousel-image {
        width: calc(90vw);
        height: auto;
    }

    .close-button, .nav-button {
        padding: 6px;
        font-size: 18px;
    }
}
