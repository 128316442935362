@import '../../variables.scss';

.Dashboard{
    padding: 20px;
    padding-top: 70px;
    background-color: rgb(240, 240, 240);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    
    .container{
        max-width: 700px;    
        position: relative;
    }

    h1{
        margin: 0 0 10px 0;
    }

    a{
        display: inline-block;
        width: 300px;
        height: 80px;
        margin: 10px 5px;
        padding: 10px;
        background-color: rgb(245, 245, 245);
        box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.15);
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        
        

        &:hover{
            background-color: $anchors-bg-color;
            transition: all 0.2s ease-in-out;
        }

        div{
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            svg{
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
        }
    }
}



